<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="wrapper-sm">
      <e-row class="m-b-sm">
        <e-col style="max-width: 150px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="90px"
          >
            <erp-input autofocus simple-border v-model="categoria.id" readonly />
          </erp-s-field>
        </e-col>

      </e-row>
      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label="Nome:"
              label-width="90px"
          >
            <erp-input autofocus simple-border v-model="categoria.nome" />
          </erp-s-field>
        </e-col>
      </e-row>
    </div>

    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <slot name="success">
      <div class="text-center">
        <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
      </div>
      <h5 class="m-t" v-if="id">{{ id ? 'Atualizado com sucesso!' : 'Acessório registrado com sucesso!' }}</h5>
      <div class="text-center m-t">
        <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
      </div>
    </slot>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import {
  ErpInput,
  ErpSField,
  WindowContent,
  WindowFooter
} from 'uloc-vue-plugin-erp'
import ERow from "../../../../layout/components/Row";
import ECol from "../../../../layout/components/Col";
import {
  find,
  newChecklistAcessorioCategoria,
  updateChecklistAcessorioCategoria
} from "../../../../../domain/remocao/services/gerenciamento-checklist/checklist-acessorios-categoria";
import WindowSuccess from "../../../../layout/window/Success";
import TimerCloseWindow from "../../../../layout/window/TimerClose";
import WindowLoading from "../../../../layout/window/Loading";

export default {
  name: 'ChecklistAcessorioCategoriaWindow',
  props: ['id'],
  data () {
    return {
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      categoria: {
        nome: null
      }
    }
  },
  mounted () {
    this.id && this.load()
  },
  methods: {
    changeTab ({tab}) {
      this.tabActive = tab
    },
    load () {
      this.loading = true
      find(this.id)
          .then(response => {
            this.categoria = response.data
          })
          .catch(error => {
            console.log(error)
            this.errors = error
          })
          .finally(() => {
            this.loading = false
          })
    },
    save () {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.categoria))
      const method = this.id ? updateChecklistAcessorioCategoria(this.id, data) : newChecklistAcessorioCategoria(data)
      method
          .then(() => {
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                this.loading = false
                this.success = true
                this.$uloc.window.emit(this.$root.wid, 'update', this.id)
                this.$refs.timer.start()
                // this.$uloc.window.close(this.$root.wid)
              })
            }
            cbSuccess()
          })
          .catch(error => {
            this.errors = error.data
            console.error(error, error.data)
            this.loading = false
          })
    }
  },
  components: {
    WindowLoading,
    TimerCloseWindow,
    WindowSuccess,
    ECol,
    ERow,
    ErpSField,
    ErpInput,
    WindowContent,
    WindowFooter
  }
}
</script>
